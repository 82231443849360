import React from "react"
import { Form, Card, Col, Row, Alert } from "react-bootstrap"
import ErrorMessage from "../../../../components/ErrorMessage"

// todo: fetch it from backend
const txn_types = [
  {name: "cash", description: "Paid in cash"},
  {name: "mpesa_direct", description: "Paid via Mpesa directly to our account"}
]

const ManualPaymentForm = ({ formik, error }) => {
  const txn_types_data = txn_types.map(txn_type => {
    return (
      <option key={txn_type.name} value={txn_type.name}>
        {txn_type.description}
      </option>
    )
  })
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <ErrorMessage errors={error} />
          </Col>
        </Row>
        <Row>
        <Col>
            <Form.Group className="mb-3" controlId="reason">
              <Form.Label>Transaction Type</Form.Label>
              <Form.Select
                value={formik.values.transaction_type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="transaction_type"
              >
                <option>Select type</option>
                {txn_types_data}
              </Form.Select>
              {formik.errors.transaction_type && formik.touched.transaction_type && (
                <Form.Text className="text-danger">
                  {formik.errors.transaction_type}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Note</Form.Label>
              <Form.Control
                type="text"
                placeholder="note/Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="description"
              />
              {formik.errors.description && formik.touched.description && (
                <Form.Text className="text-danger">
                  {formik.errors.description}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                placeholder="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="amount"
              />
              {formik.errors.amount && formik.touched.amount && (
                <Form.Text className="text-danger">
                  {formik.errors.amount}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Reference (if cash, put your name)</Form.Label>
              <Form.Control
                type="text"
                placeholder="reference number"
                value={formik.values.reference_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="reference_number"
              />
              {formik.errors.reference_number && formik.touched.reference_number && (
                <Form.Text className="text-danger">
                  {formik.errors.reference_number}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ManualPaymentForm
