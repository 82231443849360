import { apiSlice } from "../../../app/api/apiSlice"

const ppoeSubscriptionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPpoeSubscriptions: builder.query({
      query: (params) => {
        let url = `/api/isp/ppoe/subscriptions`
        if (params.pageSize) {
          url = url + `?page_size=${params.pageSize}`
        }
        if (params.pageNumber) {
          url = url + `&page_number=${params.pageNumber}`
        }
        return {
          url,
        }
      },
    }),
  }),
})

export const { useGetPpoeSubscriptionsQuery } = ppoeSubscriptionApiSlice
