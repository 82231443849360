import React, { useState } from "react"
import Dashboard from "../../../components/Dashboard"
import { useGetPpoeSubscriptionsQuery } from "./subscriptionApiSlice"
import { formatDate } from "../../../helpers"
import { Card, Row, Col } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import { notify } from "../../../helpers"
import { useNavigate } from "react-router-dom"

const columns = [
  { name: "count", description: "#" },
  { name: "first_name", description: "Firstname" },
  { name: "last_name", description: "Lastname" },
  { name: "phone_number", description: "Phone" },
  { name: "status", description: "Status" },
  { name: "account_number", description: "Account Number" },
  { name: "used_time", description: "Used Time" },
  { name: "downloaded_data", description: "Downloaded Data" },
  { name: "uploaded_data", description: "Uploaded Data" },
  { name: "last_seen", description: "Last Seen" },
  { name: "expires_at", description: "Expires At" },
  { name: "activated_at", description: "Activated At" },
]

const PPPSubscriptions = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const {
    data,
    error: errors,
    isLoading,
    refetch,
  } = useGetPpoeSubscriptionsQuery({
    pageSize: 50,
    pageNumber: currentPage,
  })

  //   handlers
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }

  const handleViewProfile = (row) => {
    navigate(`/isp/ppp/customer/profile/${row.customer_uuid}`)
  }

  //   actions
  const actions = [
    { title: "View Customer Details", handler: handleViewProfile },
  ]

  // format data
  const formatedData = Array.isArray(data?.data)
    ? data?.data?.map((sub, index) => {
        return {
          ...sub,
          count: index + 1,
          expires_at: formatDate(sub.expires_at),
          activated_at: formatDate(sub.activated_at),
          last_seen: formatDate(sub.last_seen),
        }
      })
    : []

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isLoading={isLoading}
          error={errors}
          onPageChnage={handlePageChange}
          isSelectable={false}
          actions={actions}
          onRefresh={onRefresh}
          pagination={{
            currentPage: currentPage,
            pageSize: data?.page_size,
            totalItems: data?.total_count,
            next:
              currentPage < data?.total_pages
                ? currentPage + 1
                : data?.total_pages,
            previous: currentPage > 1 ? currentPage - 1 : 1,
            pagesTotal: data?.total_pages,
          }}
        />
      </Card.Body>
    </Card>
  )

  return (
    <Dashboard title={"PPPoe Subscriptions"}>
      <Row>
        <Col>{content}</Col>
      </Row>
    </Dashboard>
  )
}

export default PPPSubscriptions
